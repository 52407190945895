import { useEffect, useRef } from "react";
import styled from "@emotion/styled";

const GiscusWrapper = styled.div`
  // Renderer Style
  display: flex;
  flex-direction: column;
  grap: 2rem;
  width: 768px;
  margin: 0 auto;
  padding: 100px 0;
  word-break: break-all;

  // Markdown Responsive Design
  @media (max-width: 768px) {
    width: 100%;
    padding: 80px 20px;
    line-height: 1.6;
  }
`

const Giscus = () => {
    const giscusRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://giscus.app/client.js';
      script.setAttribute('data-repo', 'binghe819/blog-comments');
      script.setAttribute('data-repo-id', 'R_kgDOLNsSGA');
      script.setAttribute('data-category', 'comments');
      script.setAttribute('data-category-id', 'DIC_kwDOLNsSGM4Cc7fE');
      script.setAttribute('data-mapping', 'pathname');
      script.setAttribute('data-strict', '0');
      script.setAttribute('data-reactions-enabled', '1');
      script.setAttribute('data-emit-metadata', '0');
      script.setAttribute('data-input-position', 'bottom');
      script.setAttribute('data-theme', 'light');
      script.setAttribute('data-lang', 'ko');
      script.setAttribute('data-loading', 'lazy');
      script.setAttribute('cross-origin', 'anonymous');
      script.async = true;
  
      if (giscusRef.current) {
        giscusRef.current.appendChild(script);
      }
    }, []);
  
    return (
        <GiscusWrapper>
            <div id="giscus-wrap">
                <div ref={giscusRef} />
            </div>
        </GiscusWrapper>
    );
  };

export default Giscus
