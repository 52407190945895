import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import Giscus from './Giscus'

type PostContentProps = {
  html: string
}

const PostContentWrapper = styled.div`
`

const MarkdownRenderer = styled.div`
  // Renderer Style
  display: flex;
  flex-direction: column;
  width: 768px;
  margin: 0 auto;
  padding: 100px 0;
  word-break: break-all;

  font-family: "Noto Sans KR";

  // Markdown Style
  line-height: 1.8;
  font-size: 16px;
  font-weight: 400;

  // Apply Padding Attribute to All Elements
  p {
    padding: 3px 0;
  }

  // Adjust Heading Element Style
  h1,
  h2,
  h3 {
    font-weight: 800;
    margin-bottom: 20px;
    border-bottom: 0.5px solid hsla(210,18%,87%,1);
  }

  * + h1,
  * + h2,
  * + h3 {
    margin-top: 80px;
  }

  hr + h1,
  hr + h2,
  hr + h3 {
    margin-top: 0;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 20px;
  }

  // Adjust Quotation Element Style
  blockquote {
    margin: 10px 0;
    padding: 5px 15px;
    border-left: 2px solid #000000;
    font-weight: 600;
    color: #656d76
  }

  table {
    display: block;
    max-width: 100%;
    overflow: auto;
    margin-top: 0;
    margin-bottom: 16px;
    border-spacing: 0;
    border-collapse: collapse;
    box-sizing: border-box;
  }

  table th {
    padding: 6px 13px;
    border: 1px solid;
  }

  table td {
    padding: 6px 13px;
    border: 1px solid;
  }

  // Adjust List Element Style
  ol,
  ul {
    margin-left: 20px;
  }

  // Adjust Horizontal Rule style
  hr {
    border: 0.5px solid hsla(210,18%,87%,1);
    margin-top: 0;
    margin-bottom: 30px;
  }

  // Adjust Link Element Style
  a {
    color: #4263eb;
    text-decoration: underline;
  }

  // image caption
  .gatsby-resp-image-figcaption {
    color: #6B6B6B;
    font-style: italic;
    font-weight: normal;
    margin-top: 10px;
    font-size: 0.7rem;
    text-align: center;
  }

  // Adjust Code Style
  pre[class*='language-'] {
    margin: 15px 0;
    padding: 15px;
    font-size: 15px;

    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 3px;
    }
  }

  code[class*='language-'],
  pre[class*='language-'] {
    tab-size: 2;
    white-space: pre;
  }
  
  // Markdown Responsive Design
  @media (max-width: 768px) {
    width: 100%;
    padding: 80px 20px;
    line-height: 1.6;
    font-size: 14px;

    h1 {
      font-size: 23px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 17px;
    }

    hr {
      margin-bottom: 20px;
    }
  }
`

const PostContent: FunctionComponent<PostContentProps> = function ({ html }) {
  return (
    <PostContentWrapper>
      <MarkdownRenderer dangerouslySetInnerHTML={{ __html: html }} />
      <Giscus />
    </PostContentWrapper>
  )
}

export default PostContent
